import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { PlayerContextProvider } from "./Contexts/PlayerContext";
import { DatabaseContextProvider } from "./Contexts/DatabaseContext";

import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

document.title = "How People Talk";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <DatabaseContextProvider>
        <PlayerContextProvider>
          <App />
        </PlayerContextProvider>
      </DatabaseContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
